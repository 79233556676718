import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'
import { AnimatePresence, motion } from 'framer-motion'

import * as common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'
import { useI18n } from '@rushplay/i18n'

import * as ClientConfiguration from '../client-configuration'
import { DynamicImage, toImageUrl } from '../dynamic-image'
import { mapIndexed } from '../map-indexed'

const Background = styled(motion.div)`
  ${props =>
    css({
      backgroundImage: props.background,
      padding: 2,
      alignItems:
        props.variant === 'centered'
          ? ['flex-end', null, 'center']
          : 'flex-end',
    })};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: flex-end;
`

function backgroundWidth(size) {
  switch (size) {
    case 'large': {
      return 1200
    }
    case 'medium': {
      return 800
    }
    default: {
      return 500
    }
  }
}

export function FeaturedGame(props) {
  const i18n = useI18n()
  const dpr = ClientConfiguration.useDevicePixelRatio()
  const [activeGameIndex, setActiveGameIndex] = React.useState(0)

  React.useEffect(() => {
    if (props.games.length > 1) {
      const id = setTimeout(() => {
        setActiveGameIndex(index => (index + 1) % props.games.length)
      }, 10000)

      return () => {
        clearTimeout(id)
      }
    }
  }, [activeGameIndex, props.games])

  function backgroundSrc(size, bgUrl) {
    return (
      bgUrl &&
      `radial-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8)), url(${toImageUrl(
        props.imgproxyUrl,
        bgUrl,
        {
          dpr,
          resizingWidth: backgroundWidth(size),
          extension: 'jpg',
        }
      )})
      `
    )
  }

  if (R.isEmpty(props.games)) {
    return (
      <common.Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={2}
        flex="1"
      >
        {props.children}
      </common.Box>
    )
  }

  return (
    <common.Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      p={2}
      position="relative"
      flexGrow="1"
      flexShrink="1"
      flexBasis="0"
      overflowX="hidden"
      width="100%"
      backgroundColor="rgba(0,0,0,0.5)"
    >
      <AnimatePresence exitBeforeEnter>
        {mapIndexed(
          (item, index) =>
            activeGameIndex === index && (
              <Background
                key={index}
                animate={{ opacity: 0.8 }}
                initial={{ opacity: 0 }}
                exit={{ opacity: 0, transition: { duration: 1 } }}
                transition={{ duration: 2 }}
                background={[
                  backgroundSrc('small', item.backgroundUrl),
                  backgroundSrc('medium', item.backgroundUrl),
                  backgroundSrc('large', item.backgroundUrl),
                ]}
                variant={props.variant}
              >
                <common.Box display="grid" justifyItems="center" gridGap="0">
                  <common.Box bg="g-bg" borderRadius={0} padding={0}>
                    <common.Text
                      fontSize={[2, 2, 3]}
                      fontFamily="head"
                      fontWeight="bold"
                    >
                      {i18n.translate('featured-game')}
                    </common.Text>
                  </common.Box>
                  <DynamicImage
                    alt={item.title}
                    display={['none', 'inline']}
                    dpr={dpr}
                    imgproxyUrl={props.imgproxyUrl}
                    resizingType="fit"
                    source={item.titleUrl}
                    resizingHeight={40}
                    resizingWidth={250}
                    maxWidth="250px"
                  />
                  <common.Text
                    fontSize={[2, 2, 3]}
                    fontFamily="head"
                    fontWeight="bold"
                    color="static-white"
                  >
                    {item.title}
                  </common.Text>
                  <common.Text
                    fontSize={[2, 2, 3]}
                    fontFamily="head"
                    color="static-white"
                  >
                    {item.displayProvider || item.provider}
                  </common.Text>
                </common.Box>
              </Background>
            ),
          props.games
        )}
      </AnimatePresence>
      {props.children}
    </common.Box>
  )
}

FeaturedGame.propTypes = {
  children: PropTypes.node,
  imgproxyUrl: PropTypes.string,
  games: PropTypes.array,
  variant: PropTypes.oneOf(['centered']),
}
