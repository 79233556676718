import * as React from 'react'
import PropTypes from 'prop-types'

import { UniversalFetch } from '../universal-fetch'

import { FeaturedGame } from './featured-game'

export function FeaturedGameContainer(props) {
  return (
    <UniversalFetch onFetch={props.onFetch}>
      <FeaturedGame {...props} />
    </UniversalFetch>
  )
}

FeaturedGameContainer.propTypes = {
  onFetch: PropTypes.func.isRequired,
}
